import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import Router, { withRouter } from 'next/router'
import getConfig from 'next/config'
import queryString from 'query-string'
import LeftBarNavigationMenu from '../LeftBarNavigationMenu/dynamic'

import {
  TRAFFIC_PAGE_PATH,
  VISITOR_PAGE_PATH,
  INCIDENT_PAGE_PATH,
  VISITOR_HISTORY_PAGE_PATH,
  SETTING_PAGE_PATH,
  SETTING_URL_PAGE_PATH,
  TENANT_PATH,
  MEMBER_PATH,
  USER_MANAGEMENT_PATH,
  SYSTEM_HEALTH_PAGE_PATH,
} from '../../routes'

import LeftBarNavigationStyled from './styled'
import TrafficControlImgSrc from '../../assets/images/leftbar-navigation/chart.svg'
import VisitorImgSrc from '../../assets/images/leftbar-navigation/visitor.svg'
import IncidenceImgSrc from '../../assets/images/leftbar-navigation/incidence.svg'
import FraserLogoImgSrc from '../../assets/images/leftbar-navigation/logo-fpt.svg'
import HamburgurMenuImgSrc from '../../assets/images/header/hambeger-menu.svg'
import SettingMenuImgSrc from '../../assets/images/header/api-setting.svg'
import SystemHealthImgSrc from '../../assets/images/system-health/system-health-icon.svg'

import { findMatchPermissionsAtLeastSuperUser } from '../../utils'

import { withTranslation } from '../../../i18n'

const { publicRuntimeConfig = {} } = getConfig() || {}

export class LeftBarNavigation extends React.Component {
  gotoPath = path => {
    Router.push(path)
    if (this.props.isExpand) {
      this.props.onToggleLeftBar()
    }
  }

  getLeftbarConfig() {
    const incidentQuery = queryString.stringify({
      loadHeatMap: true,
    })
    const listMenu = [
      {
        imgSrc: TrafficControlImgSrc,
        text: this.props.t('traffic_control_tower'),
        path: TRAFFIC_PAGE_PATH,
        permissions: ['read:traffic'],
      },
      {
        imgSrc: VisitorImgSrc,
        text: this.props.t('visitor'),
        path: VISITOR_PAGE_PATH,
        subPath: [
          { text: this.props.t('realtime_operation'), path: VISITOR_PAGE_PATH, permissions: ['read:visitor'] },
          { text: this.props.t('history_log'), path: VISITOR_HISTORY_PAGE_PATH, permissions: ['read:visitor-history'] },
        ],
        permissions: ['read:visitor', 'read:visitor-history'],
      },
      {
        imgSrc: IncidenceImgSrc,
        text: this.props.t('incident'),
        path: `${INCIDENT_PAGE_PATH}?${incidentQuery}`,
        permissions: ['read:incident'],
      },
      {
        imgSrc: SystemHealthImgSrc,
        text: this.props.t('system_health'),
        path: SYSTEM_HEALTH_PAGE_PATH,
        permissions: ['superuser'],
      },
      {
        imgSrc: SettingMenuImgSrc,
        text: this.props.t('setting'),
        path: SETTING_PAGE_PATH,
        subPath: [
          { text: this.props.t('tenants'), path: TENANT_PATH, permissions: ['read:tenant'] },
          { text: this.props.t('members'), path: MEMBER_PATH, permissions: ['read:member-management'] },
          { text: this.props.t('url_setting'), path: SETTING_URL_PAGE_PATH, permissions: ['read:setting'] },
          { text: this.props.t('user_management'), path: USER_MANAGEMENT_PATH, permissions: ['read:user-management'] },
        ],
        permissions: ['read:user-management', 'read:setting', 'read:member-management', 'read:tenant'],
      },
    ]
    const preparedLeftBarConfig = listMenu.reduce((prev, current) => {
      let preparedListMenu = [...prev]
      let canAccessMenu = true
      const isMenuNeedPermission = Array.isArray(current.permissions) && current.permissions.length > 0
      if (isMenuNeedPermission) {
        canAccessMenu = this.isUserHavePermission(current.permissions)
      }
      const doHaveSubMenu = Array.isArray(current.subPath)
      let preparedSubMenu = current.subPath
      if (doHaveSubMenu) {
        preparedSubMenu = preparedSubMenu.filter(subMenuData => {
          const requirePermissions = subMenuData.permissions
          return this.isUserHavePermission(requirePermissions)
        })
      }
      if (canAccessMenu) {
        preparedListMenu = [...prev, { ...current, subPath: preparedSubMenu }]
      }
      return preparedListMenu
    }, [])
    return preparedLeftBarConfig
  }

  isUserHavePermission(requirePermissions) {
    const matchPermissions = findMatchPermissionsAtLeastSuperUser(requirePermissions, this.props.user?.permissions)
    return matchPermissions.length > 0
  }

  getLeftbarNavigation() {
    const preparedListMenu = this.getLeftbarConfig()
    const menus = preparedListMenu.map(menuData => {
      const { pathname } = this.props.router
      const preparePath = menuData.path.split('?')[0]
      const isSelected = pathname.indexOf(preparePath) !== -1
      return (
        <LeftBarNavigationMenu
          id={`menu-${menuData.path}`}
          key={menuData.path}
          menuData={menuData}
          pathname={pathname}
          onClick={this.gotoPath}
          selected={isSelected}
        />
      )
    })
    return <div className="navigation-container">{menus}</div>
  }

  handleLogoClicked = () => {
    this.gotoPath(TRAFFIC_PAGE_PATH)
  }

  getLeftBarHeader() {
    return (
      <div className="left-bar-header-container">
        <div
          id="left-bar-hamburgur-menu"
          data-test="hamburgur-menu"
          className="left-bar-hamburgur-menu-container"
          onClick={this.props.onToggleLeftBar}
        >
          <img src={HamburgurMenuImgSrc} />
        </div>
        <div className="header-logo-img-container" onClick={this.handleLogoClicked}>
          <img src={FraserLogoImgSrc} />
        </div>
      </div>
    )
  }

  getBottomZoneLeftBar() {
    return (
      <div className="left-bar-bottom-zone-container">
        <div className="version-text">
          Version {publicRuntimeConfig.appVersion}({publicRuntimeConfig.GIT_COMMIT_ID})
        </div>
        <div className="trade-mark-container">© 2020 Sertis Co., Ltd.</div>
      </div>
    )
  }

  handleLeftBarBackgroundClicked = e => {
    e.stopPropagation()
  }

  getLeftBarContent() {
    return (
      <div className="left-bar-content-wrapper" onClick={this.handleLeftBarBackgroundClicked}>
        {this.getLeftBarHeader()}
        {this.getLeftbarNavigation()}
        {this.getBottomZoneLeftBar()}
      </div>
    )
  }

  render() {
    const className = this.props.isExpand ? 'expand' : ''
    return (
      <LeftBarNavigationStyled id={this.props.id} data-test={this.props.id} className={className} onClick={this.props.onToggleLeftBar}>
        {this.getLeftBarContent()}
      </LeftBarNavigationStyled>
    )
  }
}

LeftBarNavigation.defaultProps = {
  id: 'left-bar-panel',
  isExpand: false,
}

LeftBarNavigation.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    nickname: PropTypes.string,
    name: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    locale: PropTypes.string,
    updated_at: PropTypes.string,
    sub: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    role: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  }),
  router: PropTypes.shape({
    pathname: PropTypes.string,
    route: PropTypes.string,
    query: PropTypes.object,
    asPath: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  isExpand: PropTypes.bool,
  onToggleLeftBar: PropTypes.func.isRequired,
}

export default compose(withRouter, withTranslation())(LeftBarNavigation)
