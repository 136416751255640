import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .left-bar-content-wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 320px;
    height: 100%;
    background: #292929;
    z-index: 5;
    transition: 0.2s ease-in-out;
    .left-bar-header-container {
      width: 100%;
      height: 56px;
      padding: 12px 32px;
      border-bottom: 2px solid #202020;
      .left-bar-hamburgur-menu-container {
        display: none;
      }
      .header-logo-img-container {
        width: 98px;
        height: 32px;
        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .navigation-container {
      width: 100%;
      height: calc(100% - 56px - 70px - 40px); /* 56px is header height, 70px is bottom zone height, 40px is margin of bottom zone container*/
      padding-top: 100px;
      overflow: auto;
    }
    .left-bar-bottom-zone-container {
      width: 100%;
      color: #f2f2f2;
      font-size: 12px;
      padding: 0 32px;
      .version-text {
        position: relative;
        padding: 8px 0px;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #f2f2f2;
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }
      .trade-mark-container {
        padding: 8px 0px;
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeM}) {
    .left-bar-content-wrapper {
      left: -320px;
    }
    &.expand {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(18, 18, 18, 0.95);
      z-index: 1;
      .left-bar-content-wrapper {
        left: 0px;
        .left-bar-header-container {
          display: flex;
          align-items: center;
          padding: 12px 24px;
          .left-bar-hamburgur-menu-container {
            display: flex;
            margin-right: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    &.expand {
      .left-bar-content-wrapper {
        .left-bar-header-container {
          padding: 12px 16px;
        }
      }
    }
  }
`
